import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/Personal-Website/Personal-Website/src/components/layout/MD_Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`A New Transit Visualization`}</h1>
    <p>{`Abstract: Local transit systems make me wonder what my `}<a parentName="p" {...{
        "href": "#proposed-visualization"
      }}>{`ideal transit visualization`}</a>{` is.`}</p>
    <p>{`With a cool new transit plan comes a bunch of cool new visualizations. The
City of Madison's metro
transit has been drafting a `}<a parentName="p" {...{
        "href": "https://www.cityofmadison.com/metro/routes-schedules/transit-network-redesign"
      }}>{`Transit Network Redesign`}</a>{` and as a public
transportations #1 fan I have been following along.`}</p>
    <p>{`In `}<a parentName="p" {...{
        "href": "https://madison.legistar.com/View.ashx?M=F&ID=10441388&GUID=5805ED65-B6CC-495D-9F58-461C3183203D"
      }}>{`Appendix A`}</a>{`
several visualizations are included highlighting the updated bus route and
the improvements that it will make. For instance in the image below you can see the changes in service frequency by area
in the city.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1117px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c073dc0846cf58777b81b83f21d44c93/76a99/Plan_Image.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADW0lEQVQ4yzWUSXPbRhCF+aNTlf+RSw5ZKgcntpzYjpfYLi+ho7LEiFoscYG4gCCJjdgGGOwgAOpLkYq7qi/TNW9ez+vXnR9/+oFHR78d8ujoIU+fPeHFy+c8f/GMV3+95Oj3R1zffKGpa4SUlHXFdluR5xlSRqRpQlHkeJ6LEAGduTZlNB5wq4xR5zO225IsTXAdmygUlEVOGIXILCL1Aipf0jY1u7Ymy2NkLIhjSVkW7HYtHVvXWapzbNtEBD5VVeB7LktNRV+vsC2TMBSs/Qm9wWsmvTNSGdHWFa7UudF6h/q2Ku8BfcfBMnQsy8A2DbIsodyWRJE4MG3qLUIIROCh+ysub3t4gcVu26Btbhks+iTx/6zvWjr73n3PwXEs1uYCxzIRyoo8TohiSSACHM/FDQLSJONm1MdyNQJps7RvcTc2G8vka3SKMqdtGqJYMDT6KMsrTFXDCwI2vovtuTi+jxN4eEKwNlWOR28YTPso2hXn02OGkwtkEpBkEZ29QnmeouoKM+Wca62HF4eHy57v4+5TBERRzCZweNJ/wJ9nDxipF8z1IWeL91xoXRS9j+5P6RTZXkWPiXaJOR5jqTOW5pyNsFDXY5ZrFcfxWNhL/jj7lae9B0zNKYanM9B6DJc9JsYVbmiQV/EeMEOEDqfqR4bzHt5EpT/+xKv+Y86+fOJZ9yHfH33Htz9/w+PPD5FRTChjfOGz0mfYmzWeY7EtS+7udnSKPCeIN/Qmf6NoX9AcBWV9yblyzOXomO7NGx59/oXu1TtM18IJBDJNMI01q9WMMPTup6Gp7wH3k56XCdfLUy7Hp/juBsNYMV3e0F90ycqMPC84nb9jZJyz8R0MU8cw1kgZHiYkFD5pGtO29b0od7vdQaWL6xN6111mxoB/R10W+oS8SJiaA/4ZvUZdKoTCu3dGVRJKSZLGyEiQpwl1vaWTJDFN3eBJm5PJW46HrziZvqd/eoK3Nvk8fcvp7CNZFOFuLALfPfi4bVsCGSE9n9jY0FQVzZ5hlqW0TcvKn/Bh/ISbVY+5pZBGEU1ZsXAViiph1zSHpfA19gwtz0OGEXWeH7w8HA3pHCyWpVR1Thjft7PfLPuzsipotvUBaG/JfP942xw+P4wlvgjIipxtUx+WyO2twn8rPEudw2XOvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image from appendix A",
            "title": "Image from appendix A",
            "src": "/static/c073dc0846cf58777b81b83f21d44c93/76a99/Plan_Image.png",
            "srcSet": ["/static/c073dc0846cf58777b81b83f21d44c93/5a46d/Plan_Image.png 300w", "/static/c073dc0846cf58777b81b83f21d44c93/0a47e/Plan_Image.png 600w", "/static/c073dc0846cf58777b81b83f21d44c93/76a99/Plan_Image.png 1117w"],
            "sizes": "(max-width: 1117px) 100vw, 1117px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This visualization got me curious of the other visualizations that were available for public transportation. More specifically
I was looking for a method that could clearly show the effectiveness of a cities public transit. My gripe with the image
above is that I don't care about service frequency. If a bus comes every 10 minutes, but it takes the same amount of time to get
somewhere it does me no good.`}</p>
    <p>{`The beginning of my quest to find other visualizations that better fit my ideal led me to this `}<a parentName="p" {...{
        "href": "https://medium.com/@stevepepple/visualizing-better-transportation-data-tools-e48b8317a21c"
      }}>{`article`}</a>{`
which included an entire list of transit visualizations and examples. Problem is all the visualizations in this list seemed to be iterations of the same idea.
This `}<a parentName="p" {...{
        "href": "https://www.esri.com/arcgis-blog/products/network-analyst/analytics/cool-tools-for-analysis-with-public-transit-data/"
      }}>{`blog post`}</a>{` from 2016
summarized well what I was seeing. Visualizations showed distance to transit center, or ease of traveling to certain locations but missed the
opportunity to answer the biggest question, `}<strong parentName="p">{`How fast can I get from A to B?`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/25826a7ad4bbe320195ca11b5d393344/e5715/ChicagoLibraryAccess.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADbElEQVQozyWSWVNadwDF+QydvrQxUZaoeWyb2IeOk2bSiSz3/u+KSBEDMqiVTMCkWHGpibIISJSwyX4vixeEIsJlkcUihTim7UMn0+n0pZ+lQ3w7L785yxzOT3rtgkYpFMEKuUy3pI75t7J5f7QWo3uZctzRVSv7Bm21RTNec8RtMfssE1894HFH+bwxPm+MM6+eff5MQ+DkU6VCPacI0+5czt2hze3iYa0dZpup0+B2OOHslD3RrRVvyn3/iy+5I6MC/kdYLAYRz0bk2M02KZ9n6zDnq1V9paDlnLb2Xeslyhl9ueQ3Ldeuj4vdRLpNCSHR7VtcgWB8AEMweLaoWt1coRP2fDWYLkeq6b18I8EGdxoK+YVG1bOt5i0vIkf2N00q0c+ubz6/c5sv4N/AEBBLgHAKvN5+SWXdkbXltvL7C62q+VRZks90fl7JvS+8O3vL+Dei7VSBemOVEcN37wluOsMwAgAiFEqMJn3hNBCz/hi0bxYd6xWb6fK1sevfYa8yzet0op8JnYcqgd36tn5y8pvhOwIBf3wAD8zFsFw2bdDNG178kKlTpydOl2+30aKaFX/Sa6nbTGw3eZZ3Boqe1gd2fkn56SdDdwX3OACgEAQAQAFAn0xB0yRZKLrT57Fg8rDgMpZLATrju/Su51px7+9FRzPCXP+yf56eevSQyxvjEIQUwwipVIZhBADozIyMYvzZ8F4maY8GdwtFL1P0UK2IvcNEPxQa/5VT/5aC1fDC40e3RkYHzhIJjOMkSUhRBJcrZl3LmlPZ9DvKzPay2bTzffmg1winLhm25v/7iqb+qZu9jvFhAZc7xkFRHCCDzCiKwwAlEGxzUaXXqsLOtQvGXvktlc97/0xYWMocbtCHhVA4Yd2zrn59f2KwNgCoUCR5MiW6KQ/DKAQj30mAYU7RW9FmWnHv8dvOK/2vMZuvXyhdJY+7dNy3I3747Qh3lCORwACgBCFFUZwkpzGMQBEMQXDd0nyFPTq7Zs4yvsq8Mr4w59o3+bP7nr8qF3+caFXE559xOWIxBEEAx0kEwXCcxHGSIEiRENItq2OlUC5qc+o1dqvRVQqEbMZizMnW/RTlWFvTDQ3xOBiKIwgmEklgGPl4GPRGSElyUTNr2zHQR6+YE1eknjo42AjmA3TGnawy2aRr8sHE/+sOp/zTUiTkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image from appendix A",
            "title": "Image from appendix A",
            "src": "/static/25826a7ad4bbe320195ca11b5d393344/e5715/ChicagoLibraryAccess.png",
            "srcSet": ["/static/25826a7ad4bbe320195ca11b5d393344/5a46d/ChicagoLibraryAccess.png 300w", "/static/25826a7ad4bbe320195ca11b5d393344/0a47e/ChicagoLibraryAccess.png 600w", "/static/25826a7ad4bbe320195ca11b5d393344/e5715/ChicagoLibraryAccess.png 768w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Proposed Visualization`}</h2>
    <p>{`My ideal visualization to show this question is a heatmap with an input start location and color gradient weighted by the amount of
time to reach that location. Overlaying these heatmaps we could find transit dead zones on a map and also can show a cities
progress to lowering travel times when new lines are proposed.`}</p>
    <p>{`The key difference is that this visualization shows what the others are hinting at.`}</p>
    <h2>{`Issues`}</h2>
    <p>{`Although I say this is my ideal I can see why this has not been made. Calculating the optimal time to get from one side of town to
the other is complicated by alternate routes, a mixture of transit methods, and the time of day.`}</p>
    <h2>{`Progress towards Completion`}</h2>
    <p>{`None, I just made this article. 🤷`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      